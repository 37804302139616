import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiEndpoints } from "../../utils/ApiEndpoints";

// Download Invoice CSV Thunk
const DownloadInvoiceThunk = createAsyncThunk(
    "DownloadInvoice/DownloadInvoiceThunk",
    async ({ endDate }, { rejectWithValue }) => {
        try {
            console.log('endDate', endDate)
        const response = await axios.post(`${ApiEndpoints.Invoices.DownloadInvoice}?endDate=${endDate}`, 
        {},
        {
            headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });

        return response.data;
        } catch (error) {
        console.log("error", error);
        const errorMessage = error?.response?.data?.message || "Something went wrong";
        return rejectWithValue(errorMessage);
        }
    }
);

const DownloadInvoiceSlice = createSlice({
  name: "DownloadInvoice",
  initialState: {
    DownloadInvoiceData: [],
    success: false,
    error: false,
    loading: false,
    errorMessage: "",
  },
  reducers: {
    ResetDownloadInvoiceData: (state, action) => {
      state.DownloadInvoiceData = [];
      state.success = false;
      state.error = false;
      state.loading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: {
    [DownloadInvoiceThunk.pending]: (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    [DownloadInvoiceThunk.fulfilled]: (state, action) => {
      state.DownloadInvoiceData = action.payload;
      state.success = true;
      state.loading = false;
    },
    [DownloadInvoiceThunk.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.error = true;
      state.loading = false;
    },
  },
});

export default DownloadInvoiceSlice.reducer;

export const { ResetDownloadInvoiceData } = DownloadInvoiceSlice.actions;

export { DownloadInvoiceThunk };
