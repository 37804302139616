import React, { useState, useEffect } from "react";

import DatePicker from "react-datepicker";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  FormGroup,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
  Spinner,
  ModalHeader,
} from "reactstrap";
import toast from "react-hot-toast";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getLatLng } from "react-places-autocomplete";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxPopover } from "@reach/combobox";

import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  RSelect,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { NumberOfLessonsOptions } from "../pre-built/orders/OrderData";
import { GetLessonDetailThunk } from "../../redux/LessonsSlice/GetLessonDetailSlice";
import { GetStudentLessonsThunk } from "../../redux/StudentsSlice/GetStudentLessonsSlice";
import { GetStudentInstructorsThunk } from "../../redux/StudentsSlice/GetStudentInstructorsSlice";
import { ResetScheduleLessonData, ScheduleLessonThunk } from "../../redux/LessonsSlice/ScheduleLessonSlice";
import { AddLessonsThunk, ResetAddStudentLessonsData } from "../../redux/LessonsSlice/AddStudentLessonsSlice";
import { ResetUnScheduleLessonData, UnScheduleLessonThunk } from "../../redux/LessonsSlice/UnScheduleLessonSlice";
import { RescheduleLessonThunk, ResetRescheduleLessonData } from "../../redux/LessonsSlice/RescheduleLessonSlice";
import { ChangeStudentPickupLocationThunk, ResetChangeStudentPickupLocationData } from "../../redux/LessonsSlice/ChangeStudentLessonPickupLocationSlice";
import { DeleteLessonThunk, ResetDeleteLessonData } from "../../redux/LessonsSlice/DeleteStudentLessonSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ChangeLessonTypeThunk, ResetChangeLessonTypeData } from "../../redux/LessonsSlice/ChangeLessonTypeSlice";
import StudentLessonInCarReport from "./StudentLesson/StudentInCarReports/StudentLessonInCarReport";
import AddInCarReports from "./StudentLesson/StudentInCarReports/AddInCarReports";
import { ChangeLessonStatusThunk, ResetChangeLessonStatusData } from "../../redux/LessonsSlice/ChangeLessonStatusSlice";
import { ChangeLessonExpiryDateThunk, ResetChangeLessonExpiryData } from "../../redux/LessonsSlice/ChangeLessonExpiryDateSlice";
import { GetStudentPersonalDetailThunk } from "../../redux/StudentsSlice/StudentPersonalDetail";
import AddLessonModal from "./StudentLesson/AddLessonModal";
import ChangeInstructorModal from "./StudentLesson/ChangeInstructorModal";
import DeleteLessonModal from "./StudentLesson/DeleteLessonModal";

const StudentLessons = ({ 
  ready,
  value,
  status, data ,
  setValue,
  clearSuggestions }) => {
  const { studentId } = useParams();
  const dispatch = useDispatch();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const { loading, studentLessonsData } = useSelector((state) => state.Students.getStudentLessons);

  const {
    loading: studentInstructorLoading,
    studentInstructorsData,
  } = useSelector((state) => state.Students.getStudentInstructors);
  const {
    loading: lessonDetailLoading,
    lessondetailData,
  } = useSelector((state) => state.lessons.getLessonDetail);
  const {
    loading: scheduleLessonLoading,
    success: scheduleLessonSuccess,
    error: scheduleLessonError,
    errorMessage: scheduleLessonErrorMessage
  } = useSelector((state) => state.lessons.scheduleLesson);
  const {
    loading: RescheduleLessonLoading,
    success: RescheduleLessonSuccess,
    error: RescheduleLessonError,
    errorMessage: RescheduleLessonErrorMessage,
  } = useSelector((state) => state.lessons.reScheduleLesson);
  const {
    loading: ChangeLessonPickupLocationLoading,
    success: ChangeLessonPickupLocationSuccess,
    error: ChangeLessonPickupLocationError,
    errorMessage: ChangeLessonPickupLocationErrorMessage,
  } = useSelector((state) => state.lessons.changeStudentPickupLocation);
  const {
    loading: ChangeLessonTypeLoading,
    success: ChangeLessonTypeSuccess,
    error: ChangeLessonTypeError,
    errorMessage: ChangeLessonTypeErrorMessage,
  } = useSelector((state) => state.lessons.changeLessonType);
  const {
    loading: ChangeLessonStatusLoading,
    success: ChangeLessonStatusSuccess,
    error: ChangeLessonStatusError,
    errorMessage: ChangeLessonStatusErrorMessage,
  } = useSelector((state) => state.lessons.changeLessonStatus);
  const {
    loading: ChangeLessonExpiryDateLoading,
    success: ChangeLessonExpiryDateSuccess,
    error: ChangeLessonExpiryDateError,
    errorMessage: ChangeLessonExpiryDateErrorMessage,
  } = useSelector((state) => state.lessons.changeLessonExpiryDate);
  const {
    loading: unScheduleLessonLoading,
    success: unScheduleLessonSuccess,
    error: unScheduleLessonError,
    errorMessage: unScheduleLessonErrorMessage,
  } = useSelector((state) => state.lessons.unScheduleLesson);

  const [selected, SetSelected] = useState(false);
  const [selectedLessonId, setSelectedLessonId] = useState();
  const [selectedLessonDetail, setLessonsDetail] = useState();
  const [loadingClass, setLoadingClass] = useState(false);
  const [mapButton, setMapButton] = useState(false);
  const [instructorList, setInstructorList] = useState([]);
  const [expiryDate, setExpiryDate] = useState();
  const [studentPickupAddress, setStudentPickupAddress ] = useState("")
  const [modal, setModal] = useState({
    AddLesson: false,
    ScheduleLesson: false,
    UnscheduleLesson: false,
    LessonDetail: false,
    DeleteLesson: false,
    changePickupLocation: false,
    lessontype: false,
    viewInCarReport: false,
    addInCarReports: false,
    lessonStatus: false,
    expiryDate: false,
    changeInstructor: false
  });
  const [lessonDetail, setLessonDetail] = useState({
    pickupLocation: "",
    latitude: "",
    longitude: "",
    date: "",
    time: "",
  });

  useEffect(() => {
    dispatch(GetStudentLessonsThunk({ studentId }));
    dispatch(GetStudentInstructorsThunk({ studentId }));
    dispatch(GetStudentPersonalDetailThunk({ studentId }))
  }, []);

  useEffect(() => {
    const list = studentInstructorsData?.instructors?.map((instructor) => {
      return { label: instructor?.fullName, value: instructor?._id };
    });
    setInstructorList(list);
  }, [studentInstructorsData]);

  useEffect(() => {
    let timeoutId = null;

    if (!ready) {
      timeoutId = setTimeout(() => {
        // dispatch(updateIsReady(true));
        // window.location.reload();
      }, 2000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [ready]);
  

  // submit function to add a new item

  const handleScheduling = (lessonStatus) => {
    const addOnHours = moment(lessonDetail.time).toDate().getHours();
    const addOnMinutes = moment(lessonDetail.time).toDate().getMinutes();
    lessonStatus === "Awaiting Scheduling"
      ? dispatch(
        ScheduleLessonThunk({
          lessonId: lessondetailData.lesson._id,
          pickupLocation: lessonDetail.pickupLocation,
          latitude: lessonDetail.latitude,
          longitude: lessonDetail.longitude,
          date: moment(lessonDetail.date).utcOffset(0, true).toISOString(),
          time: moment(lessonDetail.time)
            .startOf("year").year(2022)
            .add("hours", addOnHours)
            .add("minutes", addOnMinutes)
            .utcOffset(0, true)
            .toISOString(),
        })
      )
      : dispatch(
        RescheduleLessonThunk({
          lessonId: lessondetailData.lesson._id,
          pickupLocation: lessonDetail.pickupLocation,
          latitude: lessonDetail.latitude,
          longitude: lessonDetail.longitude,
          date: moment(lessonDetail.date).utcOffset(0, true).toISOString(),
          time: moment(lessonDetail.time)
            .startOf("year").year(2022)
            .add("hours", addOnHours)
            .add("minutes", addOnMinutes)
            .utcOffset(0, true)
            .toISOString(),
        })
      );
  };

  const handlePickupLocation = (second) => { 
    dispatch(ChangeStudentPickupLocationThunk({
      lessonId: lessondetailData.lesson._id,
      pickupLocation: lessonDetail.pickupLocation,
      latitude: lessonDetail.latitude,
      longitude: lessonDetail.longitude,
    }))
  }

  const handleChangeLessonType = (e) => { 
    e.preventDefault();
    dispatch(ChangeLessonTypeThunk({lessonId: selectedLessonDetail._id}))
  }

  const handleChangeLessonStatus = (e) => { 
    e.preventDefault();
    dispatch(ChangeLessonStatusThunk({lessonId: selectedLessonDetail._id}))
  }

  const handleChangeLessonExpiryDate = (e) => { 
    e.preventDefault();
    dispatch(ChangeLessonExpiryDateThunk({lessonId: selectedLessonDetail._id, expiryDate: moment(expiryDate).utcOffset(0, true).toISOString()}))
  }

        // Success APIs

  if (scheduleLessonSuccess) {
    toast.success("Lesson has been scheduled successfully.");
    dispatch(ResetScheduleLessonData());
    dispatch(GetStudentLessonsThunk({ studentId }));
    setModal({ ScheduleLesson: false });
  }

  if (unScheduleLessonSuccess) {
    toast.success("Lesson has been unscheduled successfully.");
    dispatch(ResetUnScheduleLessonData());
    dispatch(GetStudentLessonsThunk({ studentId }));
    setModal({ UnscheduleLesson: false });
  }

  if(ChangeLessonPickupLocationSuccess){
    toast.success("Lesson's Pickup location has been changed successfully.");
    dispatch(ResetChangeStudentPickupLocationData());
    dispatch(GetStudentLessonsThunk({ studentId }));
    setModal({ changePickupLocation: false });
  }

  if (RescheduleLessonSuccess) {
    toast.success("Lesson has been Rescheduled successfully.");
    dispatch(ResetRescheduleLessonData());
    dispatch(GetStudentLessonsThunk({ studentId }));
    setModal({ ScheduleLesson: false });
  }

  if(ChangeLessonTypeSuccess){
    toast.success("Lesson type has been updated successfully.");
    dispatch(GetStudentLessonsThunk({ studentId }));
    dispatch(ResetChangeLessonTypeData());
    setModal({ lessonType: false });
  }

  if(ChangeLessonStatusSuccess){
    toast.success("Lesson Status has been updated successfully.");
    dispatch(GetStudentLessonsThunk({ studentId }));
    dispatch(ResetChangeLessonStatusData());
    setModal({ lessonStatus: false });
  }

  if(ChangeLessonExpiryDateSuccess){
    toast.success("Lesson Expiry Date has been updated successfully.");
    dispatch(GetStudentLessonsThunk({ studentId }));
    dispatch(ResetChangeLessonExpiryData());
    setExpiryDate();
    setModal({ expiryDate: false });
  }

      // Error Handling 
  if (scheduleLessonError) {
    toast.error(scheduleLessonErrorMessage);
    dispatch(ResetScheduleLessonData());
    setModal({ ScheduleLesson: false });
  }

  if (RescheduleLessonError) {
    toast.error(RescheduleLessonErrorMessage);
    dispatch(ResetRescheduleLessonData());
    setModal({ ScheduleLesson: false });
  }

  if (ChangeLessonPickupLocationError) {
    toast.error(ChangeLessonPickupLocationErrorMessage);
    dispatch(ResetChangeStudentPickupLocationData());
    setModal({ changePickupLocation: false });
  }

  if (unScheduleLessonError) {
    toast.error(unScheduleLessonErrorMessage);
    dispatch(ResetUnScheduleLessonData());
    dispatch(GetStudentLessonsThunk({ studentId }));
    setModal({ UnscheduleLesson: false });
  }

  if(ChangeLessonTypeError){
    toast.error(ChangeLessonTypeErrorMessage);
    dispatch(ResetChangeLessonTypeData());
    setModal({lessonType: false})
  }

  if(ChangeLessonStatusError){
    toast.error(ChangeLessonStatusErrorMessage);
    dispatch(ResetChangeLessonStatusData());
    setModal({lessonStatus: false})
  }

  if(ChangeLessonExpiryDateError){
    toast.error(ChangeLessonExpiryDateErrorMessage);
    dispatch(ResetChangeLessonExpiryData());
    setExpiryDate();
    setModal({expiryDate: false})
  }

  if (loadError) return console.log('Error loading maps');
	if (!isLoaded) return <Spinner />;

  return (
    <React.Fragment>
      <Head title="Student's Lessons"></Head>
      <Content>
        {loading || !isLoaded ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <Spinner />
          </div>
        ) : (
          <Block>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle page>Student's Lesson</BlockTitle>
                  <BlockDes className="text-soft">
                    <p><span style={{fontWeight: "900", color: "#000000"}}>Total Refund Amount: </span> ${(studentLessonsData?.totalRefundAmount)?.toFixed(2)} </p>
                  </BlockDes>
                  <BlockDes className="text-soft">
                    <p>You have total {studentLessonsData?.lessons?.length} lessons.</p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Button color="primary" onClick={() => setModal({ AddLesson: true })}>
                    <Icon name="plus" />
                    <span>Add Lesson</span>
                  </Button>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Card className="card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner p-0">
                  <table className="table table-tranx">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-id">
                          <span className="">#</span>
                        </th>
                        <th className="tb-tnx-info">
                        <span className="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Instructor Name</span>
                          </span>
                          <span className="tb-tnx-date d-md-inline-block d-none">
                            <span className="d-none d-md-block">
                              <span>Date</span>
                              <span>Time</span>
                            </span>
                          </span>
                        </th>
                        <th className="tb-tnx-amount is-alt">
                          <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                        </th>
                        <th className="tb-tnx-action">
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {studentLessonsData?.lessons?.length > 0
                        ? studentLessonsData?.lessons?.map((lessonDetail, index) => {

                          return (
                            <tr key={lessonDetail.id} className="tb-tnx-item" style={{backgroundColor: `${lessonDetail?.homePickup ? "#7460FB20": "#36A0CD15"}`}}>
                              <td className="tb-tnx-id" >
                                <div
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <span>{index + 1 } <span style={{color: "red"}}> {lessonDetail?.isComplementary && "(C)"} </span></span>
                                </div>
                              </td>
                              <td className="tb-tnx-info">
                                <div className="tb-tnx-desc">
                                  <span className="title">{lessonDetail.instructorName}</span>
                                </div>
                                <div className="tb-tnx-date">
                                  <span className="date">
                                    {lessonDetail.dateTime ? moment.utc(lessonDetail.dateTime).format("DD/MM/YYYY") : "-"}
                                  </span>
                                  <span className="date">
                                    {lessonDetail.dateTime ? moment.utc(lessonDetail.dateTime).format("LT") : "-"}
                                  </span>
                                </div>
                              </td>
                              <td className="tb-tnx-amount is-alt">
                                <div className="tb-tnx-status">
                                  <span
                                    className={`badge badge-dot badge-${lessonDetail.status === "Completed"
                                      ? "success"
                                      : lessonDetail.status === "Scheduled" ? "warning"
                                        : lessonDetail.status === "Expired" ? "gray" 
                                        : "danger"
                                      }`}
                                  >
                                    {lessonDetail.status}
                                  </span>
                                </div>
                              </td>
                              <td className="tb-tnx-action">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-plain">
                                    <li>
                                        <DropdownItem
                                          onClick={(ev) => {
                                            setLessonsDetail(lessonDetail);
                                            dispatch(GetLessonDetailThunk({ lessonId: lessonDetail._id }));
                                            setModal({ LessonDetail: true });
                                          }}
                                        >
                                          View Detail
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          onClick={(ev) => {
                                            setLessonsDetail(lessonDetail);
                                            setModal({ lessonType: true });
                                          }}
                                        >
                                          Change Lesson Type
                                        </DropdownItem>
                                      </li>
                                      {lessonDetail?.status === "Awaiting Scheduling" && (
                                        <li>
                                          <DropdownItem
                                            onClick={(ev) => {
                                              setSelectedLessonId(lessonDetail?._id);
                                              setModal({ changeInstructor: true });
                                            }}
                                          >
                                            Change Instructor
                                          </DropdownItem>
                                        </li>
                                      )}
                                      {(lessonDetail?.status === "Awaiting Scheduling" || lessonDetail?.status === "Expired") && (
                                        <>
                                          <li>
                                            <DropdownItem
                                              onClick={(ev) => {
                                                setLessonsDetail(lessonDetail);
                                                setModal({ lessonStatus: true });
                                              }}
                                            >
                                              Change Status
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              onClick={(ev) => {
                                                setLessonsDetail(lessonDetail);
                                                setModal({ expiryDate: true });
                                              }}
                                            >
                                              Change Expiry Date
                                            </DropdownItem>
                                          </li>

                                        </>
                                      )}

                                      {/* <li>
                                        <DropdownItem
                                          onClick={(ev) => {
                                            setLessonsDetail(lessonDetail);
                                            setModal({ viewInCarReport: true });
                                          }}
                                        >
                                          View In-Car Report
                                        </DropdownItem>
                                      </li> */}
                                      {lessonDetail?.status !== "Expired" && (
                                        <li>
                                          <DropdownItem
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              dispatch(GetLessonDetailThunk({ lessonId: lessonDetail?._id }));
                                              setModal({ ScheduleLesson: true });
                                              console.log('studentLessonsData[0]?.pickupLocation', studentLessonsData[0]?.pickupLocation)
                                              setStudentPickupAddress(studentLessonsData?.lessons[0]?.pickupLocation)
                                            }}
                                          >
                                            {lessonDetail.status === "Awaiting Scheduling"
                                              ? "Schedule"
                                              : "Reschedule"}
                                          </DropdownItem>
                                        </li>
                                      )}
                                      {(lessonDetail.status !== "Awaiting Scheduling" && lessonDetail.status !== "Expired") && (
                                        <li>
                                          <DropdownItem
                                            onClick={(ev) => {
                                              setSelectedLessonId(lessonDetail?._id);
                                              dispatch(GetLessonDetailThunk({ lessonId: lessonDetail?._id }));
                                              setModal({ changePickupLocation: true });
                                            }}
                                          >
                                            Change Pickup Location
                                          </DropdownItem>
                                        </li>
                                      )}
                                      {(lessonDetail.status !== "Awaiting Scheduling" && lessonDetail.status !== "Expired") && (
                                        <li>
                                          <DropdownItem
                                            onClick={(ev) => {
                                              setSelectedLessonId(lessonDetail?._id);
                                              dispatch(GetLessonDetailThunk({ lessonId: lessonDetail?._id }));
                                              setModal({ UnscheduleLesson: true });
                                            }}
                                          >
                                            Unschedule
                                          </DropdownItem>
                                        </li>
                                      )}
                                      <li>
                                        <DropdownItem
                                          onClick={(ev) => {
                                            setSelectedLessonId(lessonDetail?._id);
                                            setModal({ DeleteLesson: true });
                                          }}
                                        >
                                          Delete
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                        : <p className="p-3">No Data Found</p>}
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>
          </Block>
        )}


        {/* ************************* SCHEDULE LESSON **************************** */}
        <Modal
          isOpen={modal.ScheduleLesson}
          toggle={() => setModal({ ScheduleLesson: false })}
          className="modal-md"
        >
          <ModalHeader toggle={() => setModal({ ScheduleLesson: false })}>Edit Lesson</ModalHeader>
          <ModalBody>
            <form className="form-validate is-alter">
              {lessonDetailLoading || !isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                  <Spinner />
                </div>
              ) : (
                <Row className="gx-4 gy-3">
                  <Col size="12">
                    {console.log('studentPickupAddress', studentPickupAddress)}
                    <div>Previous Address: {studentPickupAddress}</div>
                  </Col>
                  <Col size="12">
                    <FormGroup>
                      <label className="form-label" htmlFor="event-title">
                        Select Location
                      </label>
                      <div className="form-control-wrap">
                        <Combobox
                          onSelect={async (address) => {
                            setValue(address, false);
                            clearSuggestions();
                            SetSelected(true);
                            setLoadingClass(true);

                            try {
                              const results = await getGeocode({ address });
                              const { lat, lng } = await getLatLng(results[0]);
                              setLessonDetail({
                                ...lessonDetail,
                                latitude: lat,
                                longitude: lng,
                                pickupLocation: address,
                              });
                            } catch (error) {
                              console.log(error);
                            } finally {
                              setLoadingClass(false);
                              setMapButton(false);
                            }
                          }}
                        >
                          <div className="map-input-search" style={{ position: "relative", zIndex: 1 }}>
                            <ComboboxInput
                              value={value}
                              style={{
                                borderColor: "black",
                                padding: "15px 12px",
                                width: "100%",
                                textAlign: "left",
                                fontFamily: "inherit",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "18px",
                                color: "#071d27",
                                outline: "none",
                              }}
                              className="map-input form-control"
                              onChange={(e) => {
                                setValue(e.target.value);
                              }}
                              disabled={!ready}
                              autocomplete={true}
                              placeholder="Enter an address"
                            />
                          </div>
                          <ComboboxPopover>
                            <div className={selected ? "display-none" : "container"}>
                              <div className="row">
                                <div className="no-padding-mobile">
                                  <ComboboxList
                                    className="map-box map-box-suggestions"
                                    style={{
                                      background: "#ffffff",
                                      border: "1px solid #dfe5e8",
                                      boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.14)",
                                      borderRadius: "4px",
                                      padding: "15px",
                                      marginLeft: "15px",
                                      position: "relative",
                                      zIndex: "50000",
                                    }}
                                  >
                                    {status === "OK" &&
                                      data.map(({ place_id, description }) => (
                                        <>
                                          <ComboboxOption
                                            style={{ cursor: "pointer" }}
                                            key={place_id}
                                            value={description}
                                          />
                                          <hr />
                                        </>
                                      ))}
                                  </ComboboxList>
                                </div>
                              </div>
                            </div>
                          </ComboboxPopover>
                        </Combobox>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <label className="form-label">Start Date &amp; Time</label>
                      <Row className="gx-2">
                        <div className="w-55">
                          <div className="form-control-wrap">
                            <DatePicker
                              selected={lessonDetail.date}
                              onChange={(date) => setLessonDetail({ ...lessonDetail, date: date })}
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                        <div className="w-45">
                          <div className="form-control-wrap has-timepicker">
                            <DatePicker
                              selected={lessonDetail.time}
                              onChange={(time) => setLessonDetail({ ...lessonDetail, time: time })}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between gx-4 mt-1">
                      <li>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={scheduleLessonLoading || RescheduleLessonLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handleScheduling(lessondetailData?.lesson?.status);
                          }}
                        >
                          {scheduleLessonLoading || RescheduleLessonLoading ? (
                            <Spinner />
                          ) : lessondetailData?.lesson?.status === "Awaiting Scheduling" ? (
                            "Schedule Lesson"
                          ) : (
                            "Reschedule lesson"
                          )}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-dim"
                          onClick={(e) => {
                            e.preventDefault();
                            setModal({ ScheduleLesson: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              )}
            </form>
          </ModalBody>
        </Modal>

        {/* ************************* CHANGE PICKUP LOCATION **************************** */}
        <Modal
          isOpen={modal.changePickupLocation}
          toggle={() => setModal({ changePickupLocation: false })}
          className="modal-md"
        >
          <ModalHeader toggle={() => setModal({ changePickupLocation: false })}>Change Pickup Location</ModalHeader>
          <ModalBody>
            <form className="form-validate is-alter">
              {lessonDetailLoading || !isLoaded ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                  <Spinner />
                </div>
              ) : (
                <Row className="gx-4 gy-3">
                  <Col size="12">
                    <FormGroup>
                      <label className="form-label" htmlFor="event-title">
                        Select Pickup Location
                      </label>
                      <div className="form-control-wrap">
                        <Combobox
                          onSelect={async (address) => {
                            setValue(address, false);
                            clearSuggestions();
                            SetSelected(true);
                            setLoadingClass(true);
                            try {
                              const results = await getGeocode({ address });
                              const { lat, lng } = await getLatLng(results[0]);
                              setLessonDetail({
                                ...lessonDetail,
                                latitude: lat,
                                longitude: lng,
                                pickupLocation: address,
                              });
                            } catch (error) {
                              console.log(error);
                            } finally {
                              setLoadingClass(false);
                              setMapButton(false);
                            }
                          }}
                        >
                          <div className="map-input-search " style={{ position: "relative", zIndex: 1 }}>
                            <ComboboxInput
                              className="map-input form-control combobox-input"
                              value={value}
                              onChange={(e) => {
                                setValue(e.target.value);
                              }}
                              disabled={!ready}
                              autocomplete={true}
                              placeholder="Enter an address"
                            />
                          </div>
                          <ComboboxPopover>
                            <div className={selected ? "display-none" : "container"}>
                              <div className="row">
                                <div className="no-padding-mobile">
                                  <ComboboxList className="map-box map-box-suggestions combobox-list">
                                    {status === "OK" &&
                                      data.map(({ place_id, description }) => (
                                        <>
                                          <ComboboxOption
                                            style={{ cursor: "pointer" }}
                                            key={place_id}
                                            value={description}
                                          />
                                          <hr />
                                        </>
                                      ))}
                                  </ComboboxList>
                                </div>
                              </div>
                            </div>
                          </ComboboxPopover>
                        </Combobox>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between gx-4 mt-1">
                      <li>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={ChangeLessonPickupLocationLoading}
                          onClick={(e) => {
                            e.preventDefault();
                            handlePickupLocation();
                          }}
                        >
                          {ChangeLessonPickupLocationLoading ? (
                            <Spinner />
                          ) : "Change Pickup Location"}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-dim"
                          onClick={(e) => {
                            e.preventDefault();
                            setModal({ changePickupLocation: false });
                          }}
                        >
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              )}
            </form>
          </ModalBody>
        </Modal>

        {/* ************************* CHANGE LESSON TYPE **************************** */}
        <Modal
          isOpen={modal.lessonType}
          toggle={() => setModal({ lessonType: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalBody>
            <div onClick={(ev) => { ev.preventDefault(); setModal({ lessonType: false });}} className="close cursor_pointer" >
              <Icon name="cross-sm"></Icon>
            </div>
            <div className="p-2">
              <h4 className="title">Change lesson type</h4>
              <div className="mt-4">
                <Form className="row gy-4 mt-4 mb-5">
                  <Col md="12">
                    <div>
                      <span>Are you sure you want to change lesson type from <span style={{fontWeight: "bold"}}> {selectedLessonDetail?.homePickup ? "Home Pickup": "Central Pickup" } </span> to <span style={{fontWeight: "bold"}}> {selectedLessonDetail?.homePickup ?  "Central Pickup":"Home Pickup" }? </span></span>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={ChangeLessonTypeLoading} onClick={(e) => handleChangeLessonType(e)} color="primary" size="md" type="submit">
                          {ChangeLessonTypeLoading ? <Spinner size="sm" /> : "Yes"}
                        </Button>
                      </li>
                      <li>
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ lessonType: false });
                          }}
                          className="link link-light cursor_pointer"
                        >
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ************************* CHANGE LESSON STATUS **************************** */}
        <Modal
          isOpen={modal.lessonStatus}
          toggle={() => setModal({ lessonStatus: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalBody>
            <div onClick={(ev) => { ev.preventDefault(); setModal({ lessonStatus: false });}} className="close cursor_pointer" >
              <Icon name="cross-sm"></Icon>
            </div>
            <div className="p-2">
              <h4 className="title">Change lesson status</h4>
              <div className="mt-4">
                <Form className="row gy-4 mt-4 mb-5">
                  <Col md="12">
                    <span>Are you sure you want to change lesson status?</span>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={ChangeLessonStatusLoading} onClick={(e) => handleChangeLessonStatus(e)} color="primary" size="md" type="submit">
                          {ChangeLessonStatusLoading ? <Spinner size="sm" /> : "Yes"}
                        </Button>
                      </li>
                      <li>
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ lessonStatus: false });
                          }}
                          className="link link-light cursor_pointer"
                        >
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ************************* CHANGE LESSON EXPIRY DATE **************************** */}
        <Modal
          isOpen={modal.expiryDate}
          toggle={() => setModal({ expiryDate: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalBody>
            <div onClick={(ev) => { ev.preventDefault(); setModal({ expiryDate: false });}} className="close cursor_pointer" >
              <Icon name="cross-sm"></Icon>
            </div>
            <div className="p-2">
              <h4 className="title">When would you like the lesson to expire?</h4>
              <div className="mt-4">
                <Form className="row gy-4 mt-4 mb-5">
                  <Col sm="12">
                    <FormGroup>
                      <label className="form-label">Expiry Date</label>
                      <Row className="gx-2">
                        <div className="w-55">
                          <div className="form-control-wrap">
                            <DatePicker
                              selected={expiryDate}
                              onChange={(date) => setExpiryDate(date)}
                              className="form-control date-picker"
                            />
                          </div>
                        </div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button disabled={ChangeLessonExpiryDateLoading} onClick={(e) => handleChangeLessonExpiryDate(e)} color="primary" size="md" type="submit">
                          {ChangeLessonExpiryDateLoading ? <Spinner size="sm" /> : "Yes"}
                        </Button>
                      </li>
                      <li>
                        <div
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ expiryDate: false });
                          }}
                          className="link link-light cursor_pointer"
                        >
                          Cancel
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ************************* UNSCHEDULE LESSON **************************** */}

        <Modal
          isOpen={modal.UnscheduleLesson}
          toggle={() => setModal({ UnscheduleLesson: false })}
          className="modal-md"
        >
          <ModalHeader toggle={() => setModal({ UnscheduleLesson: false })}>Unschedule Lesson</ModalHeader>
          <ModalBody>
            <form className="form-validate is-alter">
              <Row className="gx-4 gy-3">
                <Col size="12">
                  <div>
                    <span style={{ fontWeight: "bold" }}> Are You sure you want to unschedule a lesson?</span>
                  </div>
                </Col>
                <Col size="12">
                  <ul className="d-flex justify-content-between gx-4 mt-1">
                    <li>
                      <Button
                        color="danger"
                        className="btn-dim"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(UnScheduleLessonThunk({ lessonId: lessondetailData?.lesson?._id }));
                        }}
                      >
                        {unScheduleLessonLoading ? <Spinner /> : "Unschedule Lesson"}
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="btn-dim"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal({ UnscheduleLesson: false });
                        }}
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>

        {/* ************************* LESSON DETAIL **************************** */}

        <Modal
          isOpen={modal.LessonDetail}
          toggle={() => setModal({ LessonDetail: false })}
          className="modal-md"
        >
          {lessonDetailLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <Spinner />
            </div>
          ) : (
            <>
              <ModalHeader
                className={`${selectedLessonDetail?.status &&
                  (selectedLessonDetail?.status === "Completed"
                    ? "fc-event-success"
                    : selectedLessonDetail?.status === "Scheduled"
                      ? "fc-event-info"
                      : "fc-event-primary")
                  }`}
                toggle={() => setModal({ LessonDetail: false })}
              >
                Lesson Detail
              </ModalHeader>
              <ModalBody>
                <Row className="gy-3 py-1">
                  {lessondetailData?.lesson?.dateTime &&
                    <Col sm="6">
                      <h6 className="overline-title">Start Time</h6>
                      <p id="preview-event-start">
                        {moment.utc(lessondetailData?.lesson.dateTime).format("hh:mm A")}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.dateTime &&
                    <Col sm="6" id="preview-event-end-check">
                      <h6 className="overline-title">End Time</h6>
                      <p id="preview-event-end">
                        {moment.utc(lessondetailData?.lesson.dateTime).add(1, "hour").format("hh:mm A")}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.city &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">City</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson?.city}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.homePickup &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">Home Pickup Location</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson?.homePickup}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.status &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">Status</h6>
                      <p id="preview-event-description">  {lessondetailData?.lesson.status}</p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.isRoadTest &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">Type</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson.isRoadTest ? "Road Test" : "Regular"}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.purchaseDate &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">Purchase Date</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson.purchaseDate.split("T")[0]}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.expiryDate &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">Expiry Date</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson.expiryDate.split("T")[0]}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.duration &&
                    <Col sm="6" id="preview-event-description-check">
                      <h6 className="overline-title">Duration</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson?.duration}
                      </p>
                    </Col>
                  }
                  {lessondetailData?.lesson?.pickupLocation &&
                    <Col sm="12" id="preview-event-description-check">
                      <h6 className="overline-title">Pickup Location</h6>
                      <p id="preview-event-description">
                        {lessondetailData?.lesson?.pickupLocation}
                      </p>
                    </Col>
                  }
                </Row>
              </ModalBody>
            </>
          )}
        </Modal>

        {/* ************************* DELETE LESSON **************************** */}

        {/* <Modal
          isOpen={modal.DeleteLesson}
          toggle={() => setModal({ DeleteLesson: false })}
          className="modal-dialog-centered"
          size="md"
        >
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();

                setModal({ DeleteLesson: false });
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h4 className="title">Delete Lesson</h4>
              <div className="mt-4">
                <Form className="row gy-4 mt-4 mb-5">
                  <Col md="12">
                    <div>
                      <h6>Are you sure you want to delete Lesson?</h6>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="d-flex justify-content-between flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button onClick={(e) => handleDeleteLesson(e)} color="danger" size="md" type="submit">
                          Delete
                          <span>{DeleteLessonLoading && <Spinner size="sm" />} </span>
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal({ DeleteLesson: false });
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
      </Content>
      
      {modal.AddLesson && <AddLessonModal modal={modal} setModal={()=>  setModal({AddLesson: false})} countryCode={studentLessonsData?.countryCode} /> }
      {modal.viewInCarReport && <StudentLessonInCarReport modal={modal} setModal={()=> setModal({viewInCarReport: false})}/>}
      {modal.addInCarReports && <AddInCarReports modal={modal} setModal={()=> setModal({addInCarReports: false})}/>}
      {modal.changeInstructor && <ChangeInstructorModal modal={modal} setModal={()=> setModal({changeInstructor: false})} lessonId={selectedLessonId} instructorList={instructorList} /> }
      {modal.DeleteLesson && <DeleteLessonModal modal={modal} setModal={()=> setModal({DeleteLesson: false})} lessonId={selectedLessonId} /> }
    
    </React.Fragment>
  );
};

export default StudentLessons;
