// const baseUrl = "https://api.kruzee.com/api/admin";

//    *******************      Change Images Url         ******************** //
// const baseUrl = 'http://192.168.100.6:5002/api/admin';

const baseUrl = process.env.REACT_APP_BASE_URL;

const tempUrl = "https://developapi.kruzee.com/api/instructor";

export const ApiEndpoints = {
  Login: `${baseUrl}/login`,
  Profile: `${baseUrl}/getProfileData`,
  GetDashboardStats: `${baseUrl}/getDashboardStats`,
  UploadImages: `https://api.kruzee.com/api/upload`,
  GetImage: `https://api.kruzee.com/api/download`,

  PickupPoints: {
    GetAllPickupPoints: `${baseUrl}/getAllPickupPoints`,
    RemovePickupPoints: `${baseUrl}/removePickupPoint`,
    AddPickupPoints: `${baseUrl}/addPickupPoint`,
    UpdatePickupPoint: `${baseUrl}/updatePickupPoint`,
  },

  UnservedStudents: {
    GetUnservedStudents: `${baseUrl}/getQueuedStudents`,
  },

  SurveyForm: {
    GetSurveyForm: `${baseUrl}/getSurveyForms`,
  },

  Invoices : {
    GetAllInvoices: `${baseUrl}/getAllInvoices`,
    GetInvoiceDetail: `${baseUrl}/`,
    DownloadInvoicePdf: `${baseUrl}/`,
    ChangeInvoiceStatus: `${baseUrl}/markInvoicePaid`,
    EmailInvoice: `${baseUrl}/emailInvoiceSummary`,
    DownloadInvoice: `${baseUrl}/getInvoiceCSV`
  },

  InstructorsEndPoint: {
    AddInstructors: `${baseUrl}/addInstructor`,
    GetAllInstructors: `${baseUrl}/getAllInstructors`,
    GetInstructors: `${baseUrl}/getInstructorProfileDetail`,
    GetInstructorStudents: `${baseUrl}/getInstructorStudents`,
    GetInstructorReviews: `${baseUrl}/getInstructorReviews`,
    AddInstructorReviews: `${baseUrl}/addInstructorReview`,
    GetInstructorLessons: `${baseUrl}/getInstructorLessons`,
    GetInstructorVehicleDetail: `${baseUrl}/getInstructorVehicleDetail`,
    ChangeInstructorPassword: `${baseUrl}/changeInstructorPassword`,
    AssignPickupPoint: `${baseUrl}/assignPickupPoints`,
    UnassignPickupPoint: `${baseUrl}/unassignPickupPoint`,
    GetInstructorPickupLocations: `${baseUrl}/getInstructorPickupLocations`,
    UpdateInstructorStatus: `${baseUrl}/updateInstructorStatus`,
    RemoveInstructor: `${baseUrl}/removeInstructor`,
    AddInstructor: `${baseUrl}/addInstructor`,
    GetInstructorList: `${baseUrl}/getInstructorsList`,
    GetInstructorVehicleDetails: `${baseUrl}/getInstructorVehicleDetail`,
    EditInstructorProfile: `${baseUrl}/editInstructorDetails`,
    GetInstructorSchedule: `${baseUrl}/getInstructorWeeklySchedule`,
    UpdateInstructorSchedule: `${baseUrl}/updateInstructorSchedule`,
    AddInstructorSchedule: `${baseUrl}/updateInstructorSchedule`,
    AddInstructorEvent: `${baseUrl}/addInstructorEvent`,
    DeleteInstructorAvailability: `${baseUrl}/removeInstructorAvailability`,
    DeleteInstructorReviews: `${baseUrl}/removeInstructorReview`,
    DeleteInstructor: `${baseUrl}/removeInstructor`,
    AssignInstructorGeoFencing: `${baseUrl}/assignInstructorGeoFencing`,
    UnassignInstructorGeoFencing: `${baseUrl}/unAssignInstructorGeoFencing`,
    GetInstructorGeoFencing: `${baseUrl}/getInstructorGeoFencing`,
    GetAllInstructorInvoices: `${baseUrl}/getAllInstructorInvoices`,
    GetInstructorInvoiceDetail: `${baseUrl}/getInvoiceSummary`,
    AddInstructorOutOfOffice:  `${baseUrl}/addInstructorOutOfOffice`
  },

  StudentsEndPoint: {
    GetAllStudents: `${baseUrl}/getAllStudents`,
    GetStudentPersonalDetail: `${baseUrl}/getStudentProfileDetail`,
    GetStudentInstructor: `${baseUrl}/getStudentInstructorDetail`,
    GetStudentNotes: `${baseUrl}/getNotes`,
    AddStudentNotes: `${baseUrl}/addNotes`,
    AddStudent: `${baseUrl}/addStudent`,
    GetStudentLessons: `${baseUrl}/getStudentLessons`,
    AddStudentLessons: `${baseUrl}/addLessons`,
    GetStudentRoadTest: `${baseUrl}/getStudentRoadTestLesson`,
    GetStudentReviews: `${baseUrl}/getStudentReviews`,
    GetStudentInstructors: `${baseUrl}/getStudentInstructors`,
    AssignNewStudentInstructor: `${baseUrl}/assignNewStudentInstructor`,
    DeleteStudent: `${baseUrl}/deleteStudent`,
    AddStudentRoadTest: `${baseUrl}/addRoadTestLesson`,
    EditStudentProfile: `${baseUrl}/editStudentProfile`,
    ChangeStudentPassword: `${baseUrl}/changeStudentPassword`,
    GetPackages: `${baseUrl}/getPackages`,
    GetStudentRefunds: `${baseUrl}/getStudentRefunds`,
    UpdateStudentRefunds: `${baseUrl}/markRefundPaid`,
    inCarSheet: {
      removeTopic: `${baseUrl}/removeTopic`,
      GetAllInCarSheetLesson: `${baseUrl}/getLessonSheetList`,
      AddLessonInCarSheet: `${baseUrl}/addLessonSheet`,
      GetAllInCarSheetManeuvers: `${baseUrl}/getAllManeuvers`,
      InitiateStudentNewInCarSheet: `${baseUrl}/initiateNewInCarSheet`,
      GetInCarSheetRegion: `${baseUrl}/`,
      GetLessonSheetDetail: `${baseUrl}/getLessonSheet`,
      AddMultiLessonInCarSheet: `${baseUrl}/addMultiLessonSheet`,
      GenerateInCarSheet: `${baseUrl}/generateInCarSheet`
    },
  },

  LesssonsEndPoint: {
    AddStudentLessons: `${baseUrl}/addLessons`,
    GetLessonDetail: `${baseUrl}/getLessonDetails`,
    ScheduleLesson: `${baseUrl}/scheduleLesson`,
    UnScheduleLesson: `${baseUrl}/unscheduleLesson`,
    RescheduleLesson: `${baseUrl}/rescheduleLesson`,
    DeleteLessons: `${baseUrl}/deleteLesson`,
    ScheduleRoadTest: `${baseUrl}/scheduleRoadTest`,
    ChangeStudentPickupLocation: `${baseUrl}/changeLessonPickupLocation`,
    ChangeLessonType: `${baseUrl}/changeLessonType`,
    ChangeLessonStatus: `${baseUrl}/changeLessonStatus`,
    ChangeLessonExpiryDate: `${baseUrl}/changeLessonExpiryDate`,
    VerifyStripePID: `${baseUrl}/verifyStripePID`,
    ChangeLessonInstructor: `${baseUrl}/changeLessonInstructor`
  },

  PromotionsEndPoint: {
    AddSeasonalPromotion: `${baseUrl}/addNewPromotion`,
    GetAllPromotions: `${baseUrl}/getAllPromotions`,
    UpdateSeasonalPromotionStatus: `${baseUrl}/updatePromotionStatus`,
    DeleteSeasonalPromotion: `${baseUrl}/deletePromotion`,
    UpdateReferralPromotionStatus: `${baseUrl}/updateCashBackStatus`,
  },
  DroppedStudents: {
    GetDroppedStudents: `${baseUrl}/getDroppedStudents`
  },

  Configuration: {
    GetStudentNotification: `${baseUrl}/getStudentNotificationsEnabled`,
    UpdateStudentNotification: `${baseUrl}/updateStudentNotificationsEnabled`
  },

  LmsStudents: {
    GetLmsStudents: `${baseUrl}/getLmsStudents`
  },

  GeoFencing: {
    GetAllGeoFencing: `${baseUrl}/getAllGeoFencing`,
    ViewAllGeoFencing: `${baseUrl}/getAllGeoFencingForMap`,
    AddGeoFencing: `${baseUrl}/addGeoFencing`,
    UpdateGeoFencing: `${baseUrl}/updateGeoFencing`,
    DeleteGeoFencing: `${baseUrl}/deleteGeoFencing`,
  }
};
